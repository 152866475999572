import { Box, Flex, GatsbyImage } from '@stars-ecom/shared-atoms-ui'
import {
  DataLayerUtils,
  NextSteps,
  OrderConfirmationMessage,
  OrderLineShippingDetails,
  UserContext,
  WebsiteContext
} from '@stars-ecom/shared-organisms-ui'
import head from 'lodash/head'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { siteFormatter } from '../utils/metaTitleFormatter'

const OrderConfirmationPage = (props) => {
  const { pageContext, location } = props
  const validOrder = location?.state?.order
  const websiteContext = useContext(WebsiteContext)
  const currentUser = useContext(UserContext)
  const [shipping, setShipping] = useState([])

  useEffect(() => {
    const newShipping = []
    const hasHeavyProduct = validOrder?.lines?.some(
      (orderLine) =>
        orderLine?.productVariant?.product?.customFields?.isHeavyProduct &&
        ['PDT', 'FID'].includes(orderLine?.productVariant?.product?.customFields?.productType)
    )
    const hasLightProduct = validOrder?.lines?.some(
      (orderLine) =>
        !orderLine?.productVariant?.product?.customFields?.isHeavyProduct &&
        ['PDT', 'FID'].includes(orderLine?.productVariant?.product?.customFields?.productType)
    )

    if (hasHeavyProduct) {
      newShipping.push({
        shippingMethodCode: 'standard',
        address: validOrder?.shippingAddress,
        orderLines: validOrder?.lines?.filter(
          (ol) =>
            ['PDT', 'FID'].includes(ol?.productVariant?.product?.customFields?.productType) &&
            ol?.productVariant?.product?.customFields?.isHeavyProduct
        ),
        phoneNumber: validOrder?.customFields?.phone,
        cellPhoneNumber: validOrder?.customFields?.cellPhone
      })
    }
    if (hasLightProduct) {
      const shippingMethod = head(validOrder?.shippingLines)?.shippingMethod?.code
      newShipping.push({
        shippingMethodCode: head(validOrder?.shippingLines)?.shippingMethod?.code,
        address:
          shippingMethod === 'mondial-relay'
            ? {
                streetLine1: validOrder?.customFields?.relayName,
                streetLine2: validOrder?.customFields?.relayAddress,
                city: validOrder?.customFields?.relayCity,
                postalCode: validOrder?.customFields?.relayPostalCode
              }
            : validOrder?.shippingAddress,
        orderLines: validOrder?.lines?.filter(
          (ol) =>
            ['PDT', 'FID'].includes(ol?.productVariant?.product?.customFields?.productType) &&
            !ol?.productVariant?.product?.customFields?.isHeavyProduct
        ),
        phoneNumber: validOrder?.customFields?.phone,
        cellPhoneNumber: validOrder?.customFields?.cellPhone
      })
    }
    setShipping(newShipping)
    if (validOrder?.code) {
      DataLayerUtils.addOrderConfirmationEvent({
        order: validOrder,
        command: 'orderConfirmation',
        customer: currentUser
      })
    }
  }, [validOrder?.code])

  return (
    <Flex direction="column" align="center" justify="center" w="100%">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content={`Confirmation de commande - ${siteFormatter(process.env.GATSBY_API_WEBSITE)}`}
        />
        <title>{`Confirmation de commande - ${siteFormatter(
          process.env.GATSBY_API_WEBSITE
        )}`}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Flex direction="column" align="center" w="100%" justify="center">
        <OrderConfirmationMessage amount={validOrder?.totalWithTax} orderId={validOrder?.code} />
        <Flex direction={{ base: 'column', md: 'row' }} w="100%" justify="center">
          <Box>
            {shipping?.map((e, i) => (
              <OrderLineShippingDetails
                key={i}
                shippingMethodCode={e.shippingMethodCode}
                address={e.address}
                phoneNumber={e.phoneNumber}
                cellPhoneNumber={e.cellPhoneNumber}
                orderLines={e.orderLines}
                index={i + 1}
                isMultiple={shipping?.length > 1}
              />
            ))}
          </Box>
          {pageContext?.checkout?.confirmationPageBackgroundImage && (
            <Flex h={{ base: '250px', md: '340px' }}>
              <GatsbyImage
                image={
                  pageContext?.checkout?.confirmationPageBackgroundImage?.image?.childImageSharp
                    ?.gatsbyImageData
                }
                objectFit="contain"
              />
            </Flex>
          )}
        </Flex>
        {websiteContext?.websiteSettings?.webloyaltySuccess && (
          <Box id={websiteContext?.websiteSettings?.webloyaltySuccess} />
        )}
        <NextSteps />
      </Flex>
    </Flex>
  )
}

OrderConfirmationPage.pageType = 'orderConfirmation'

export default OrderConfirmationPage
